<template>
  <div class="w-100 p-0 overflow-hidden">
    <b-table
      class="position-relative"
      :items="members"
      responsive
      :small="isSmall"
      :fields="fields"
      :busy="busy"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: User -->
      <template #cell(fullname)="{ item }">
        <b-media class="d-flex align-items-center pl-1 ml-25 mt-25 ">
          <!-- <b-link :to="item.name ? getMemberLocation(item) : null" class="text-dark d-flex align-items-center"> -->
          <div class="text-dark d-flex align-items-center">
            <div>
              <user-avatar v-if="!validatorEmailValidator(item.name)" :user="item" size="32" />
            </div>
            <div class="ml-1">
              <p v-if="!validatorEmailValidator(item.name)" class="font-weight-extrabold mb-0">
                {{ `${item.name} ${item.surname}` }}
              </p>
              <span v-else class="text-muted">---</span>
            </div>
          </div>
          <!-- </b-link> -->
        </b-media>
      </template>
      <!-- Column: Headline -->
      <template #cell(headline)="{ item }">
        <p v-if="item.headline" class="mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75">
          {{ item.headline }}
        </p>
        <span v-else class="text-muted mb-0 pl-25 ml-1 mt-75">---</span>
      </template>
      <!-- Column: Updated -->
      <template #cell(updated)="{ item }">
        <p class="align-text-top text-capitalize mb-0 pl-25 ml-1 mt-75">
          <span v-if="item.lastLogin"> {{ getDate(item) }} </span>
          <span v-else class="text-muted">---</span>
        </p>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge pill :variant="getColor(data.item.status)" class="text-capitalize mb-0 ml-1 mt-75 check-button">
          {{ getStatus(data.item.status) }}
        </b-badge>
      </template>
      <!-- Column: Referral type -->
      <template #cell(type)="data">
        <p class="ml-1 pl-25 mb-0 mt-75">{{ $t('backoffice.referrals.type-options.community') }}</p>
      </template>
      <!-- Column: Referral by -->
      <template #cell(referrer)="data">
        <p class="text-capitalize ml-1 pl-25 mb-0 mt-75"> {{ $route.params.communityId }} </p>
      </template>
      <template #table-busy>
        <div class="text-center p-5">
          <b-spinner
            size="2rem"
            type="grow"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
      <template #empty>
        <div class="horizontal-placeholder d-block">
          <div>
            <img :src="membersPlaceholder" class="d-block">
          </div>
          <div>
            <p class="text-primary text-center">
              {{ $t('available.message', { itemName: $t('members.title') }) }}
            </p>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { validatorEmailValidator } from '@/@core/utils/validations/validators';

export default {
  name: 'MembersTable',
  components: { UserAvatar },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
  },
  data() {
    return {
      isSmall: false,
      isVerified: false,
      currentMember: {},
      enableStatus: '',
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'fullname',
          label: this.$t('backoffice.members.fullname'),
        },
        {
          key: 'updated',
          label: this.$t('backoffice.members.update'),
          sortable: false,
        },
        {
          key: 'type',
          label: this.$t('backoffice.referrals.type'),
          sortable: false,
        },
        {
          key: 'referrer',
          label: this.$t('backoffice.referrals.referrer'),
          sortable: false,
        },
        {
          key: 'status',
          label: this.$t('backoffice.referrals.status'),
          sortable: false,
        },
        // {
        //   key: 'actions',
        //   label: this.$t('backoffice.members.actions'),
        //   sortable: false,
        // },
      ];
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    statusMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        2: this.$t('backoffice.members.rejected'),
        3: this.$t('backoffice.members.confirmed'),
        4: this.$t('backoffice.members.rejected-admin'),
        5: this.$t('backoffice.members.rejected-user'),
        6: this.$t('backoffice.members.left'),
        7: this.$t('backoffice.members.invited'),
        8: this.$t('backoffice.members.waiting-list'),
      };
    },
    optionsEnableMember() {
      return {
        0: this.$t('backoffice.members.pending'),
        1: this.$t('backoffice.members.requested-join'),
        3: this.$t('backoffice.members.confirmed'),
        7: this.$t('backoffice.members.invited'),
      };
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }

      return {
        name: 'backoffice-members-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    getImageResource,
    getOrganizations(item) {
      return item?.organizations.map((organization) => organization.name);
    },
    getStatus(member) {
      return this.statusMember[member];
    },
    getColor(member) {
      const colorMember = {
        0: 'light',
        1: 'light-warning',
        2: 'light-secondary',
        3: 'light-success',
        4: 'light-danger',
        5: 'light-dark',
        6: 'secondary',
        7: 'light-primary',
        8: 'light-info',
      };
      return colorMember[member];
    },
    async toggleIsVerified() {
      this.isStatusModalOpened = false;
      this.isVerified = !this.isVerified;
      try {
        const member = this.members.find((item) => item.key === this.currentMember.key);
        member.status = this.isVerified === true ? 3 : 4;
        member.isMember = this.isVerified;
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'backoffice/memberStatus',
            customName: 'membersBO',
            requestConfig: {
              memberKey: this.currentMember.key,
              status: member.status,
            },
          },
        });
        this.currentMember = {};
        this.notifySuccess(this.$t('backoffice.members.modals.success-status'));
      } catch {
        this.notifyError(this.$t('backoffice.members.modals.error-status'));
      }
    },
    validatorEmailValidator,
    getDate(item) {
      const d = new Date(item.lastLogin);
      return d.toLocaleString('en-GB');
    },
  },
};
</script>
<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.check-button {
  cursor: pointer !important;
}
</style>
